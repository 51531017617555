<template>
  <div>
    <div class="item-con">
      <div class="course-wrap">
        <div>
          <span class="course-titletc">{{ teachInfoData.scheduleName }}</span
          ><span class="course-type">{{ teachInfoData.classType }}</span>
        </div>
      </div>
      <div class="course-infowrap">
        <span class="course-progress">{{ teachInfoData.structure }}</span>
        <span class="course-time course-linepd">|</span>
        <span class="course-time">{{ teachInfoData.timeValue }}</span>
      </div>
    </div>
    <div class="item-con">
      <div class="title">教学重点</div>
      <div class="report-wrap">
        <p class="teach-txt">{{ teachInfoData.description }}</p>
      </div>
    </div>
    <a-spin :spinning="materialSpin">
      <div class="item-con">
        <div class="title">课节资源</div>
        <div style="margin-top: 20px">
          <div class="list-title">
            <p>名称</p>
            <p>类型</p>
            <p>操作</p>
          </div>
          <div class="list-item" v-for="i in tableData" :key="i.uuid" @click="onCheck(i)">
            <p>
              <img :src="i.imgStr" alt="" /><span>{{ i.name }}</span>
            </p>
            <p>{{ i.materialType ? initOptionsType(i.materialType, typeArr) : '-' }}</p>
            <p class="operation">查看</p>
          </div>
          <div v-if="tableData.length === 0">
            <p class="emptyState">
              <img src="@/assets/icons/pic_emptypage.svg" alt="" />
              暂无数据
            </p>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import storage from 'store';
import { queryClassResources } from '@/api/headTeacher';
import fileIcon from '@/assets/fileIcon.png';
import imgIcon from '@/assets/imgIcon.png';
import pdfIcon from '@/assets/pdfIcon.png';
import pptIcon from '@/assets/pptIcon.png';
import videoIcon from '@/assets/videoIcon.png';
import router from '@/router/index';

export default {
  name: 'teachInfoData',

  props: {
    teachInfoData: {
      type: Object,
      default: () => {},
    },
    subject: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      materialSpin: false,
      typeArr: [
        { label: '复习', value: 'REVIEW' },
        { label: '预习', value: 'PREVIEW' },
      ],
      fileIcon,
      imgIcon,
      pdfIcon,
      pptIcon,
      videoIcon,
      previewData: null,
      tableData: [],
      reportlList: [],
      columns: [],
      mathColumns: [
        {
          title: 'Title number',
          key: 'index',
          width: 100,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: 'Correct answer',
          dataIndex: 'correct',
          width: 120,
        },
        {
          title: 'Student answer',
          dataIndex: 'answer',
          width: 120,
        },
        {
          title: 'Determine answer',
          dataIndex: 'result',
          width: 140,
          scopedSlots: { customRender: 'action' },
        },
      ],
      chineseColumns: [
        {
          title: '题号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: '正确答案',
          dataIndex: 'correct',
          width: 180,
        },
        {
          title: '学员答案',
          dataIndex: 'answer',
          width: 100,
        },
        {
          title: '最终答案',
          dataIndex: 'result',
          width: 100,
          scopedSlots: { customRender: 'action' },
        },
      ],
    };
  },
  methods: {
    initOptionsType(type, typeArr) {
      console.log(type);
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === type) {
          name = item.label;
        }
      });
      return name;
    },

    // 获取课节资源
    queryClassResources() {
      const params = {
        sectionId: this.teachInfoData.sectionId,
      };
      this.materialSpin = true;

      queryClassResources(params).then((res) => {
        this.materialSpin = false;
        this.tableData = [];
        const data = res.data.content;
        data.forEach((i) => {
          const o = {};
          o.name = i.name;
          o.convertFile = i.convertedFileList || i.convertFile;
          o.imageUrl = i.imageUrl;
          o.url = i.url;
          o.materialType = i.materialType;
          o.imgStr = this.chooseImgHandle(o.name);
          this.tableData.push(o);
        });
      });
    },
    onCheck(v) {
      storage.set('materialPreviewData', v);
      const routeUrl = router.resolve({ path: '/material-preview' });
      window.open(routeUrl.href, '_blank');
    },
    chooseImgHandle(type) {
      const str = type.includes('.') ? type.substring(type.lastIndexOf('.') + 1) : 'file';
      let s = '';
      switch (str) {
        case 'file':
          s = this.fileIcon;
          break;
        case 'jpg':
          s = this.imgIcon;
          break;
        case 'jpeg':
          s = this.imgIcon;
          break;
        case 'png':
          s = this.imgIcon;
          break;
        case 'pdf':
          s = this.pdfIcon;
          break;
        case 'mp4':
          s = this.videoIcon;
          break;
        case 'ppt':
          s = this.pptIcon;
          break;
        case 'pptx':
          s = this.pptIcon;
          break;
        default:
          break;
      }
      return s;
    },
  },
  mounted() {
    this.queryClassResources();
  },
};
</script>

<style lang="less" scoped>
.item-con {
  padding: 0 0 20px;
  border-bottom: 1px solid #eeeeee;
}
.course-titletc {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  padding-right: 12px;
}
.course-type {
  color: #ffbf00;
  padding: 0 2px;
  border-radius: 4px;
  border: 1px solid #ffbf00;
  font-size: 14px;
}
.course-infowrap {
  margin-top: 10px;
  font-size: 16px;
}
.course-progress {
  color: #333333;
}
.course-time {
  color: #999;
}
.course-linepd {
  padding: 0 10px;
}
.title {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-top: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 5px 8px 0 0;
  width: 4px;
  height: 18px;
  background-color: #43d186;
  border-radius: 0px 4px 4px 0px;
}
.student-start {
  width: 14px;
  height: 14px;
  margin-right: 2px;
  margin-top: -3px;
}
.report-wrap {
  display: flex;
  margin-top: 13px;
}
.report-label {
  width: 120px;
  font-size: 16px;
  color: #999;
}
.report-con {
  font-size: 15px;
  color: #333;
  flex: 1;
}
.bigwidth {
  width: 170px;
  text-align: right;
  padding-right: 20px;
}
.face-icon {
  width: 20px;
  height: 20px;
}
.item-con:last-child {
  border-bottom: none;
}
.list-title {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #000;
  padding: 16px 23px;
  background-color: #f8f8f8;
  & > p:nth-child(1) {
    width: 60%;
  }
  & > p:nth-child(2) {
    width: 20%;
  }
  & > p:nth-child(3) {
    width: 20%;
  }
  p {
    margin-bottom: 0;
  }
}
.list-item {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  color: #000;
  padding: 16px 23px;
  border-bottom: 1px solid #f2f2f2;
  & > p:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  & > p:nth-child(1) {
    width: 60%;
    display: flex;
    align-items: center;
  }
  & > p:nth-child(2) {
    width: 20%;
  }
  & > p:nth-child(3) {
    width: 20%;
    color: #43d186;
  }
  img {
    max-width: 22px;
    max-height: 24px;
    vertical-align: middle;
    margin-right: 10px;
  }
  p {
    margin-bottom: 0;
  }
}
.emptyState {
  height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
}
.teach-txt {
  font-size: 16px;
  line-height: 22px;
  color: #333333;
}
</style>
