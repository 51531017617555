<template>
  <div class="demo-app">
    <div class="demo-app-main" ref="main" :class="{ calendarTools: viewType === 'timeGridWeek' }">
      <!-- <div class="backMyStudent1">
        <img src="@/assets/service_icon_enter.png" class="classicon_enter" /><span>学生</span>
        <span class="backStudentName" @click="hrefMyStudent"
          >{{ studentProfile.fullName }}（{{ studentProfile.code }}）</span
        >
      </div> -->

      <div class="course-table-mask" v-show="courseTableVisible">
        <div class="course-table-wrapper">
          <div ref="courseTableRef" id="courseTableBox" :style="{ zoom: courseTableScale }">
            <CourseTableUi
              :currentDateRange="currentDateRange"
              :calenderData="calenderData"
              :studentProfile="studentProfile"
            />
          </div>
        </div>
        <div class="operation">
          <a-button :loading="createCourseTableLoading" type="primary" @click="createCourseTable">复制课表</a-button>
        </div>
        <img src="./assets/close.svg" class="close" @click="() => (this.courseTableVisible = false)" />
      </div>

      <FullCalendar ref="FullCalendar" class="demo-app-calendar" :options="calendarOptions">
        <template v-slot:eventContent="arg">
          <a-tooltip placement="topLeft">
            <template slot="title">
              <div v-html="arg.event.title"></div>
            </template>
            <div
              v-html="arg.event.title"
              v-if="viewType !== 'dayGridMonth'"
              style="display: block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
              :class="{ eventCls: popoverids === arg.event.id }"
            ></div>
          </a-tooltip>
          <a-popover
            placement="topLeft"
            trigger="click"
            :get-popup-container="getPopupContainer"
            overlayClassName="coursePopover"
            :visible="popoverids === arg.event.id"
            v-if="viewType !== 'dayGridMonth'"
          >
            <template slot="content">
              <a-icon type="close" class="closed" @click="getvisibleChange1" />
              <!-- <a-icon type="close-circle" class="closed" @click="getvisibleChange1" /> -->
              <div v-if="courseSchedule !== null" style="overflow: hidden">
                <p class="course-title">
                  <a-tooltip placement="topLeft">
                    <template slot="title">
                      <span>{{ courseSchedule.scheduleName }}</span>
                    </template>
                    <span
                      style="
                        max-width: 320px;
                        display: block;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                      "
                      >{{ courseSchedule.scheduleName }}</span
                    >
                  </a-tooltip>
                  <span class="course-status">{{ courseSchedule.classType === 'PRIVATE' ? '私教课' : '班课' }}</span>
                </p>
                <p class="course-info"><span>开课时间：</span>{{ courseSchedule.timeValue }}</p>
                <p class="course-info">
                  <span>课节状态：</span>{{ initStructureStatus(courseSchedule.scheduleStatus) }}
                </p>
                <p class="course-info"><span>课节进度：</span>{{ courseSchedule.courseSectionRespDTO.structure }}</p>
                <p class="course-info">
                  <span>授课老师：</span>{{ courseSchedule.teacher.fullName }}（{{ courseSchedule.teacher.code }}）
                </p>
                <p class="course-info"><span>所属班级：</span>{{ courseSchedule.formClassName }}</p>
                <p class="course-info">
                  <span>学生：</span
                  >{{
                    courseSchedule.students &&
                    (courseSchedule.students.length > 1 || courseSchedule.students.length === 0)
                      ? courseSchedule.students.length + '人'
                      : courseSchedule.students[0].name
                  }}
                </p>
                <p
                  class="course-info"
                  v-if="
                    changecourse &&
                    (arg.event.extendedProps.isAdjustSection === null || arg.event.extendedProps.isAdjustSection)
                  "
                >
                  <span>课节进度：</span>{{ courseSchedule.courseSectionRespDTO.sectionName }}
                </p>

                <div class="look-btnwrap">
                  <a-tooltip trigger="hover" overlayClassName="calendarPopover">
                    <template slot="title"> 教学重点 </template>
                    <span class="look-btn teachfocus" @click="teachfocus"> </span>
                  </a-tooltip>
                  <a-tooltip
                    trigger="hover"
                    overlayClassName="calendarPopover"
                    v-if="courseSchedule.scheduleStatus === 'COMPLETED'"
                  >
                    <template slot="title"> 回放课节 </template>
                    <span class="look-btn playbackclass" @click="playCourse(courseSchedule)"> </span>
                  </a-tooltip>
                  <a-tooltip
                    trigger="hover"
                    overlayClassName="calendarPopover"
                    v-if="courseSchedule.scheduleStatus === 'NORMAL'"
                  >
                    <template slot="title"> 监课课节 </template>
                    <span class="look-btn playbackclass" @click="playClass(courseSchedule)"> </span>
                  </a-tooltip>
                  <a-tooltip trigger="hover" overlayClassName="calendarPopover">
                    <template slot="title"> 课后作业 </template>
                    <span class="look-btn homework" @click="homeworkFun"> </span>
                  </a-tooltip>
                  <a-tooltip trigger="hover" overlayClassName="calendarPopover">
                    <template slot="title"> 学习报告 </template>
                    <span class="look-btn study" @click="lookReportFocus"> </span>
                  </a-tooltip>
                  <!-- <span class="section-btn" v-if="courseSchedule.scheduleStatus==='COMPLETED'" @click="playCourse(courseSchedule.uuid)">回放课节</span>
                <span class="section-btn" @click="homeworkFun">课后作业</span> -->
                </div>
              </div>
            </template>
          </a-popover>
          <div v-else :style="{ width: '100%' }">
            <a-tooltip placement="topLeft" :visible="popoverids === arg.event.id">
              <template slot="title">
                <span>{{ arg.event.title }}</span>
              </template>
              <div :style="{ backgroundColor: arg.event.backgroundColor, color: '#fff' }">{{ arg.event.title }}</div>
            </a-tooltip>
          </div>
        </template>
      </FullCalendar>
      <div class="calendarLoading" v-if="showLoading">
        <a-spin size="large" />
      </div>
      <div class="statistics-time">
        <a-checkbox-group v-model="subjectArr" @change="onChangeSubject">
          <a-checkbox value="CHINESE"> 中文 </a-checkbox>
          <a-checkbox value="MATH" class="math-check"> 数学 </a-checkbox>
          <a-checkbox value="ENGLISH" class="english-check"> 英文 </a-checkbox>
        </a-checkbox-group>
        <div class="schedule-color">
          <div style="margin-left: 8px; font-size: 16px">学生时区：{{ getTimezoneName(studentProfile.timezone) }}</div>
        </div>
        <!-- <div class="schedule-btnWrap">
          <span class="goClass" @click="goClass">去排课</span>
        </div> -->
      </div>
    </div>
    <component
      :sortValueSel="sortValueSel"
      :is="currentView"
      :homeworkTemplate="homeworkTemplate"
      :studentReview="studentReview"
      :studentId="studentId"
      :checkData="checkData"
      :teacherId="teacherId"
      :subject="subject"
      :courseScheduleId="courseScheduleId"
      @openCheckOperation="openCheckOperation"
    ></component>
    <!-- 中文课后作业未提交 -->
    <a-drawer title="查看详情" placement="right" width="700" :closable="true" :visible="checkVisible" @close="cancal">
      <CheckHomeWork
        :subject="subject"
        :checkData="checkData"
        :studentReview="studentReview"
        :scheduleData="scheduleData"
        :homeworkTemplate="homeworkTemplate"
      />
    </a-drawer>

    <!--学习报告-->
    <a-drawer
      v-if="visibleReport"
      :title="reportTitle"
      placement="right"
      width="700"
      :visible="visibleReport"
      @close="onCloseReportTC"
    >
      <StudyReport :subject="subject" :reportInfoData="reportInfoData"></StudyReport>
    </a-drawer>
    <!--教学重点-->
    <a-drawer title="教学重点" placement="right" width="700" :visible="visibleTeachfocus" @close="onCloseTeacherTC">
      <TeachingFocus v-if="visibleTeachfocus" :subject="subject" :teachInfoData="teachInfoData"></TeachingFocus>
    </a-drawer>
  </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { CheckHomeWork } from '@wk/tch-sc';
import Popover from '@/components/WKPopover/popover';
import CheckOperation from '@/views/operation/CheckOperation';
import ReviewOperation from '@/views/operation/ReviewOperation';
import { queryPlayCourse } from '@/api/schedule';
import { getTimezoneName } from '@/utils/geoLocation';
import CourseTableUi from './CourseTableUi.vue';
import html2canvas from 'html2canvas';
import { getCalendarTable, queryCourseSchedule, courseHomeWork, getCourseReport } from '@/api/headTeacher';
import StudyReport from './StudyReport.vue';
import TeachingFocus from './TeachingFocus.vue';
import { getReplayLink } from '@/api/common';
// const LOAD_NUM = 20;
const session = storage.createStore(sessionStorage);

const colors = {
  red: {
    primary: '#e74c3c',
    secondary: '#fff',
  },
  blue: {
    primary: '#009CFF',
    secondary: '#fff',
  },
  yellow: {
    primary: '#f39c12',
    secondary: '#fff',
  },
  green: {
    primary: '#00CF64',
    secondary: '#fff',
  },
  grey: {
    primary: '#999',
    secondary: '#fff',
  },
  orange: {
    primary: '#f99f0f',
    secondary: '#fff',
  },
};
export default {
  name: 'ClassCalendarAdmin',
  components: {
    FullCalendar,
    Popover,
    CheckOperation,
    ReviewOperation,
    CheckHomeWork,
    StudyReport,
    TeachingFocus,
    CourseTableUi,
  },
  data() {
    return {
      courseTableScale: 0,
      createCourseTableLoading: false,
      courseTableVisible: false,
      currentDateRange: undefined,
      calenderData: {},
      getTimezoneName,
      visibleTeachfocus: false,
      subjectArr: ['MATH', 'CHINESE', 'ENGLISH'],
      reportTitle: '学习报告',
      reportInfoData: {},
      teachInfoData: {},
      visibleReport: false,
      subject: '',
      scheduleData: {},
      checkVisible: false,
      sortValueSel: 0, // 默认选中预览题目tab
      courseScheduleId: '',
      checkData: {},
      teacherId: '',
      studentReview: [],
      submited: [],
      noSubmited: [],
      studentProfile: session.get('studentProfile'),
      homeworkTemplate: null,
      studentId: '',
      timezone: '',
      popoverids: '',
      eventsArr: null,
      FullCalendarBol: false,
      calendarOptions: {
        weekNumberCalculation: 'ISO',
        locale: 'zh-cn',
        buttonText: {
          today: '本周',
          month: '月',
          week: '周',
          day: '日',
        },
        displayEventEnd: true, // 所有视图显示结束时间
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
          hour12: false, // 设置时间为24小时
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        customButtons: {
          download: {
            text: '生成课表',
            click: this.openCourseTable,
          },
        },
        headerToolbar: {
          left: 'download prev,next today',
          center: 'title',
          right: 'timeGridWeek',
        },
        // initialDate:'',//设置初始时间
        height: 'calc( 100vh - 112px)',
        //  contentHeight: '800px',
        initialView: 'timeGridWeek',
        events: [],
        editable: false,
        selectable: false,
        selectMirror: true,
        dayMaxEventRows: true,
        views: {
          dayGridMonth: {
            dayMaxEventRows: 4,
          },
        },

        // eventContent:this.eventContent,跟v-slot：content互斥
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        allDaySlot: false,
        datesSet: this.datesSetfuc,
      },
      viewType: '', // 日历类型
      changecourse: true,
      times: [],
      courseSchedule: null, // 课程详情
      courseNameId: null, // 修改课程变量id
      selectArr: [], // 课程调级下拉
      courseBtn: false,
      showLoading: false,
      showAllTime: false,
      newDateParamsTime: null,
      availableTime: null,
      currentView: null, // 组件名称
      studentInfo: [], // 学生信息
      studentLiveUrl: '',
      workStatus: session.get('WorkerStatus'),
      newclickInfo: null,
      dataList: null,
      num: 1,
      paddingBol: false,
      imDate: '', // 从IM学习报告过来的日期
    };
  },
  computed: {
    expandBol() {
      return this.$store.state.user.expand;
    },
  },

  watch: {
    expandBol() {
      this.calendarOptions.initialView = this.viewType;
      this.initCalendar(this.newDateParamsTime);
    },
  },

  methods: {
    moment,
    async createCourseTable() {
      this.createCourseTableLoading = true;

      const canvas = await html2canvas(this.$refs.courseTableRef, {
        scale: window.devicePixelRatio || 1,
        backgroundColor: null,
        onclone: (cloneDocument) => {
          const dom = cloneDocument.getElementById('courseTableBox');
          dom.style.zoom = 1;
        },
      });
      const blob = await new Promise((resolve) => canvas.toBlob((blob) => resolve(blob)));

      const data = [
        new ClipboardItem({
          [blob.type]: blob,
        }),
      ];
      await navigator.clipboard.write(data);
      this.createCourseTableLoading = false;
      this.$message.success('复制成功');
    },
    async openCourseTable() {
      this.courseTableVisible = true;
      this.$nextTick(() => {
        const height = this.$refs.courseTableRef.offsetHeight;
        this.courseTableScale = (window.innerHeight - 180) / height;
      });
    },
    onCloseTeacherTC() {
      this.visibleTeachfocus = false;
    },
    teachfocus() {
      this.teachInfoData = {
        scheduleName: this.courseSchedule.scheduleName,
        classType: this.courseSchedule.classType === 'PRIVATE' ? '私教课' : '班课',
        timeValue: this.courseSchedule.timeValue,
        structure: this.courseSchedule.courseSectionRespDTO.structure,
        teacherName: `${this.courseSchedule.teacher.fullName}（${this.courseSchedule.teacher.code})`,
        sectionId: this.courseSchedule.courseSectionId,
        description: this.courseSchedule.courseSectionRespDTO.description,
      };
      this.visibleTeachfocus = true;
    },
    onCloseTeacherTC() {
      this.visibleTeachfocus = false;
    },
    onChangeSubject(values) {
      this.subjectArr = values;
      if (this.subjectArr.length === 0) {
        this.$refs.FullCalendar.getApi().addEventSource([]);
        return false;
      }
      this.initCalendar(this.newDateParamsTime);
    },
    // 获取学习报告
    getCourseReport() {
      const params = {
        courseScheduleId: this.courseSchedule.uuid,
        studentId: this.studentProfile.uuid,
      };
      getCourseReport(params).then((res) => {
        const data = res.data.content;
        if (data.ratingComment.ratingScore === -2 || data.ratingComment.ratingScore === -1) {
          this.$message.warning('暂无学习报告');
          return false;
        }
        this.reportInfoData = {
          scheduleName: this.courseSchedule.scheduleName,
          classType: this.courseSchedule.classType === 'PRIVATE' ? '私教课' : '班课',
          timeValue: this.courseSchedule.timeValue,
          structure: this.courseSchedule.courseSectionRespDTO.structure,
          ratingComment: data.ratingComment, // 本课评价
          classPerformance: data.classPerformance, // 课堂评价
          activeData: data.activeData, // 课堂数据
          teacherName: `${this.courseSchedule.teacher.fullName}（${this.courseSchedule.teacher.code})`,
        };
        this.visibleReport = true;
      });
    },
    lookReportFocus() {
      this.reportInfoData = {};

      this.reportTitle = this.subject === 'MATH' ? 'Teaching Focus' : '学习报告';
      this.getCourseReport();
    },

    onCloseReportTC() {
      this.visibleReport = false;
    },
    openCheckOperation(sort) {
      // 从题目详情点击进去预览题目，选中对应的tab
      this.sortValueSel = sort;
      // 打开预览作业
      this.currentView = 'CheckOperation';
      this.homeworkTemplate = session.get('homeworkTemplate');
    },
    homeworkFun() {
      (this.courseScheduleId = this.courseSchedule.uuid), (this.teacherId = this.courseSchedule.teacher.uuid);
      this.studentId = this.studentProfile.uuid;
      this.initCourseHomeWork();
    },

    // 获取预览作业
    initCourseHomeWork() {
      const params = {
        courseScheduleId: this.courseSchedule.uuid,
        courseSectionId: this.courseSchedule.courseSectionId,
        studentId: this.studentProfile.uuid,
        teacherId: this.courseSchedule.teacher.uuid,
      };
      courseHomeWork(params).then((res) => {
        this.studentReview = res.data.content;
        // 判断课后作业有没有发布，发布了，判断学生是否提交了作业，提交了展示提交的作业，否则展示预览作业
        if (this.studentReview.publish) {
          // 预览老师发布的作业
          this.checkData = res.data.content?.homeworkTemplate?.homeworkLink;
          this.homeworkTemplate = res.data.content?.homeworkTemplate;
          // session.set('homeworkTemplate',this.homeworkTemplate)
          // session.set('checkData',this.checkData)
          this.submited = this.studentReview.stuSchedules.filter((item) => {
            return item.student.submitStatus;
          });
          this.noSubmited = this.studentReview.stuSchedules.filter((item) => {
            return !item.student.submitStatus;
          });
          if (this.studentReview?.student?.caSubmitStatus) {
            // 预览学生提交的作业,里面也要展示未提交的
            this.currentView = 'ReviewOperation';
          } else {
            this.sortValueSel = 0;
            this.checkVisible = true;
          }
        } else {
          this.$message.warning('暂无作业发布');
        }
      });
    },
    hrefMyStudent() {
      this.$store.commit('setStudentName', this.studentProfile.fullName);
      this.$store.commit('setStudentIdRoute', this.studentProfile.uuid);
      this.$router.push({
        path: '/studentDetail',
        query: {
          uuid: this.studentProfile.uuid,
        },
      });
      // this.$router.push({ path: '/myStudent' });
    },

    // 课程回放
    async playCourse(record) {
      try {
        const { data } = await getReplayLink(record.uuid);
        const videoInfo = data?.[0];
        if (videoInfo?.type === 'HTML_URL') {
          window.open(videoInfo?.link);
        } else {
          window.open(`/lessonReplay?courseScheduleId=${record.uuid}`, '_blank');
        }
      } catch {
        window.open(`/lessonReplay?courseScheduleId=${record.uuid}`, '_blank');
      }
    },
    // 监课
    playClass(record) {
      // 上课平台是classIn，直接打开
      if (record.platform === 'CLASS_IN') {
        window.open(record.liveUrl, '_blank');
      } else if (record.platform === 'TALK_CLOUD') {
        this.queryPlayCourse(record.uuid);
      }
    },
    queryPlayCourse(courseScheduleId) {
      const params = {
        courseScheduleId,
      };
      queryPlayCourse(params).then((res) => {
        window.open(res.data.content.inspectorLink, '_blank');
      });
    },

    toCourseDetails(courseDateUuid) {
      this.$router.push({ path: 'courseDetails', query: { uuid: courseDateUuid } });
    },
    toCLassIn() {
      const parame = {
        uid: this.courseSchedule.teacher.classInId,
        courseId: this.courseSchedule.formClassInId,
        classId: this.courseSchedule.classInId,
        deviceType: 1,
        lifeTime: 86400,
      };
      this.$post('/api/service_center/login_link', parame).then((res) => {
        let urlStr = res.data.content.data;
        urlStr = `https://www.eeo.cn/client/invoke/index.html${urlStr.substring(urlStr.indexOf('?'))}`;
        const $iframe = document.createElement('a');
        $iframe.style.display = 'none';
        $iframe.setAttribute('href', urlStr);
        $iframe.setAttribute('target', '_blank');
        document.body.appendChild($iframe);
        $iframe.click();
        setTimeout(() => {
          document.body.removeChild($iframe);
        }, 1000);
      });
    },
    eventRender() {},
    eventContent(arg) {
      // 修改tilte，展示html
      const italicEl = document.createElement('div');
      italicEl.innerHTML = arg.event.title;
      const arrayOfDomNodes = [italicEl];
      return { domNodes: arrayOfDomNodes };
    },
    initCalendar(dateParams) {
      // 初始化课表
      const parmas = {
        studentId: this.studentProfile.uuid,
        subjects: this.subjectArr,
        // timezone:this.studentProfile.timezoneStd,
        startDate: dateParams.startDate,
        endDate: dateParams.endDate,
        studentTimeZone: this.studentProfile.timezoneStd,
      };
      console.log(this.studentProfile);
      getCalendarTable(parmas).then((res) => {
        const resData = res.data;
        this.calenderData = resData;

        const eventsArr = [];
        Object.keys(resData).forEach((value) => {
          eventsArr.push(...this.initDate(resData[value]));
        });
        this.cleanEvent('FullCalendar');
        this.showLoading = false;
        this.$refs.FullCalendar.getApi().addEventSource(eventsArr);
      });
    },
    // 工作时间
    initAvailableTime(dateParams) {
      this.$post('/api/service_center/available_time/currentweek', dateParams)
        .then((res) => {
          if (res.status === 200) {
            this.availableTime = res.data;
            if (this.workStatus.workStatus === 'WORKING_AVAILABLE') {
              if (
                (this.workStatus.workType === 'F' && Number(this.availableTime.workTime) < 50) ||
                (this.workStatus.workType === 'P' && Number(this.availableTime.workTime) < 20)
              ) {
                this.showAllTime = true;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 清空事件
    cleanEvent(name) {
      this.showLoading = true;
      this.$refs[name]
        .getApi()
        .getEvents()
        // eslint-disable-next-line array-callback-return
        .map((_) => {
          _.remove();
        });
    },
    initDate(eventList) {
      // 区分中文数学 courseSchedule.courseSectionRespDTO.course.subject
      // 课节名：courseSchedule.courseSectionRespDTO。sectionName
      // 课节时间:courseSchedule.startDateTime endDateTime
      // 学生上课状态courseSchedule.studentStatus
      // 班课：courseSchedule。classType
      // 标明颜色
      const list = [];
      if (eventList.length <= 0) return list;
      eventList.forEach((item) => {
        const { subject } = item.courseSchedule.courseSectionRespDTO.course;
        let statusColor = '';
        let status = '';
        switch (subject) {
          case 'CHINESE':
            status = '中文';
            statusColor = colors.green;
            break;
          case 'MATH':
            status = '数学';
            statusColor = colors.blue;
            break;
          case 'ENGLISH':
            status = '英文';
            statusColor = colors.orange;
            break;
          default:
            break;
        }
        const start = item.courseSchedule.startDateTime;
        const end = item.courseSchedule.endDateTime;
        list.push({
          id: item.courseSchedule.uuid,
          start,
          title: this.getTitle(item),
          subTitle: item.courseSchedule.courseSectionRespDTO.sectionName,
          textColor: statusColor.secondary,
          backgroundColor: statusColor.primary,
          end,
          color: statusColor.primary,
          className:
            this.viewType !== 'dayGridMonth' && item.customData !== null && !item.customData.isAdjustSection
              ? 'dotcls'
              : '',
          isAdjustSection: item.customData !== null ? item.customData.isAdjustSection : null,
        });
      });
      return list;
    },
    initStructureStatus(status) {
      let statusTxt = '';
      switch (status) {
        case 'NORMAL':
          statusTxt = '待开课';
          break;
        case 'COMPLETED':
          statusTxt = '已结束';
          break;
        case 'CANCELLED':
          statusTxt = '已取消';
          break;
      }
      return statusTxt;
    },
    goClass() {
      const routeUrl = this.$router.resolve({
        path: '/workoutSchedule/schedule',
      });
      window.open(routeUrl.href, '_blank');
    },
    getTitle(obj) {
      let temp = '';
      let temp1 = '';
      let temp2 = '';
      let temp3 = '';
      const { studentStatus } = obj;
      if (studentStatus && studentStatus.length > 0) {
        studentStatus.forEach((item) => {
          if (item === 'absenteeism') {
            temp = '<span class="statusFlag absent">旷课</span>';
          } else if (item === 'late') {
            temp1 = '<span class="statusFlag absent">迟到</span>';
          } else if (item === 'leave_early') {
            temp2 = '<span class="statusFlag absent">早退</span>';
          } else if (item === 'CANCELLED') {
            temp3 = '<span class="statusFlag absent">已取消</span>';
          }
        });
      }

      const titleHtml = `<div style="display:flex;justify-content: space-between;"><span style="display:block;
    max-width:200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;">${obj.courseSchedule.courseSectionRespDTO.sectionName}</span><span>${
        temp + temp1 + temp2 + temp3
      }</span></div>
      <div><span>${obj.courseSchedule.startDateTime.substring(11, 16)} - ${obj.courseSchedule.endDateTime.substring(
        11,
        16,
      )}</span><span style="padding-left:15px;">${
        obj.courseSchedule.classType === 'PRIVATE' ? '私教课' : '班课'
      }</span></div>
      `;
      return titleHtml;
    },
    // 点击事件
    handleEventClick(clickInfo) {
      this.selectArr = [];
      this.num = 1;
      this.dataList = null;
      this.newclickInfo = clickInfo;
      if (this.viewType === 'dayGridMonth') return;
      this.showLoading = true;
      if (clickInfo.event.id === 'null') {
        this.showLoading = false;
        this.$refs.FullCalendar.getApi().addEventSource([]);
        return;
      }
      this.queryCourseSchedule(clickInfo.event.id);
    },
    queryCourseSchedule(uuid) {
      const params = {
        uuid,
      };
      queryCourseSchedule(params).then((res) => {
        this.courseSchedule = res.data.content;
        this.subject = this.courseSchedule.courseSectionRespDTO.course.subject;

        this.courseSchedule.timeValue = `${this.moment(this.courseSchedule.startDateTime).format(
          'YYYY年MM月DD日',
        )} ${this.moment(this.courseSchedule.startDateTime).format('dddd')}${this.courseSchedule.startDateTime.substr(
          11,
        )}-${this.courseSchedule.endDateTime.substr(11)}`;
        this.popoverids = uuid;
        this.courseName = this.courseSchedule.scheduleName;

        // 更新日历中的事件
        this.showLoading = false;
        this.$refs.FullCalendar.getApi().addEventSource([]);
      });
    },
    debounce(fn, wait) {
      let timer = null;
      return function () {
        if (timer !== null) {
          clearTimeout(timer);
        }
        timer = setTimeout(fn, wait);
      };
    },
    // 滚动时触发（防止抖动）
    handlePopupScroll() {
      this.loadMoreData();
    },
    // 加载更多数据到select框
    loadMoreData() {
      const renderedLen = this.selectArr.length; // 已渲染的下拉列表长度
      const totalLen = this.dataList.totalCount; // 当前数据源的长度
      if (this.num > this.dataList.totalPages) return;
      if (renderedLen < totalLen) {
        if (this.paddingBol) {
          this.num += 1;
          this.getcourseId(
            this.courseSchedule.courseSectionRespDTO.course.uuid,
            this.newclickInfo.event.id,
            this.courseSchedule.courseSectionRespDTO.data.subjectType,
            this.courseSchedule.courseSectionRespDTO.data.level1,
            this.courseSchedule.courseSectionRespDTO.data.level2,
            this.num,
          );
        }
      }
    },
    // 获取课程id
    getcourseId(courseId, id, subjectType, level1, level2, num) {
      this.paddingBol = false;
      this.$post('/api/service_center/course/section/search', {
        courseId,
        courseStructure: { level1, subjectType, level2 },
        pageNum: num,
      })
        .then((res) => {
          this.dataList = res.data.content;
          this.popoverids = id;
          this.selectArr = this.selectArr.concat(res.data.content.content);
          this.showLoading = false;
          this.$refs.FullCalendar.getApi().addEventSource([]);
        })
        .finally(() => {
          this.paddingBol = true;
          this.showLoading = false;
        });
    },
    // 返回修改课程按钮
    changecourseFuc() {
      this.changecourse = false;
      this.$refs.FullCalendar.getApi().addEventSource([]);
    },
    handleSelectChange(value) {
      this.courseNameId = value;
    },
    // 更改课程提交数据
    sumCourse() {
      // this.changecourse = false;
      this.courseBtn = true;
      this.$refs.FullCalendar.getApi().addEventSource([]);
      const obj = {
        oldCourseScheduleId: this.courseSchedule.uuid,
        newCourseScheduleId: this.courseNameId || this.courseSchedule.courseSectionRespDTO.uuid,
        batch: true,
      };
      this.$post(
        `/api/service_center/course_schedule/adjust/${obj.oldCourseScheduleId}/${obj.newCourseScheduleId}/${obj.batch}`,
      )
        .then((res) => {
          if (res.status === 200) {
            this.cleanEvent('FullCalendar');
            this.popoverids = '';
            const dateParams = {
              endDate: this.times[1],
              startDate: this.times[0],
            };
            this.changecourse = true;
            this.courseBtn = false;
            this.showLoading = true;
            this.initCalendar(dateParams);
            this.$message.success('修改课节进度成功');
            this.courseNameId = null;
            this.$refs.FullCalendar.getApi().addEventSource([]);
          }
        })
        .catch((err) => {
          this.courseBtn = false;
          this.courseNameId = null;
          this.$refs.FullCalendar.getApi().addEventSource([]);
          console.log(err);
        });
    },
    // handleEvents (events) {
    // 设置事件
    // this.setEventsArr = events
    // this.$refs.FullCalendar.getApi().addEventSource([])
    // },
    getvisibleChange1() {
      this.courseSchedule = null;
      this.popoverids = '';
      this.changecourse = true;
      this.$refs.FullCalendar.getApi().addEventSource([]);
    },
    getPopupContainer(trigger) {
      return trigger.parentElement.parentElement.parentElement.parentElement.parentElement;
    },
    getPopupContainer1(trigger) {
      return trigger.parentElement.parentElement.parentElement;
    },
    datesSetfuc(dateInfo) {
      this.currentDateRange = [dateInfo.start, dateInfo.end];

      this.viewType = dateInfo.view.type;
      switch (this.viewType) {
        case 'dayGridMonth':
          this.calendarOptions.buttonText.today = '本月';
          break;
        case 'timeGridWeek':
          this.calendarOptions.buttonText.today = '本周';
          break;
        case 'timeGridDay':
          this.calendarOptions.buttonText.today = '今天';
          break;
        default:
          break;
      }
      this.showLoading = true;

      // 切换周修改日期参数 查询课程日历是 初始时间-1 结束时间+1/ 查询可用时间正常传入一周时间段
      const changeTimeEnd = new Date(dateInfo.endStr).getTime() - 24 * 60 * 60 * 1000;
      const changeTimeStart = new Date(dateInfo.startStr).getTime() - 24 * 60 * 60 * 1000;
      const dateParams = {
        endDate: moment(new Date(changeTimeEnd)).format('YYYY-MM-DD'),
        startDate: dateInfo.startStr.substr(0, 10),
      };
      const newDateParams = {
        endDate: dateInfo.endStr.substr(0, 10),
        startDate: moment(new Date(changeTimeStart)).format('YYYY-MM-DD'),
      };
      this.newDateParamsTime = newDateParams;
      console.log(newDateParams);
      //  this.showAllTime = false;
      this.initCalendar(newDateParams);
      // this.initAvailableTime(dateParams);
    },
    cancal() {
      this.currentView = null;
      this.checkVisible = false;
    },
  },
  created() {
    this.imDate = this.$route.params.date;

    // 如果从IM学习报告过来的，定位到对应的那一周,获取当前周的开始结束时间
    if (this.imDate) {
      this.calendarOptions.initialDate = this.imDate;

      //  let weekOfday = parseInt(this.$moment(this.imDate).format('d')) // 计算今天是这周第几天 周日为一周中的第一天
      // let start = this.$moment(this.imDate).subtract(weekOfday-1, 'days').format('YYYY-MM-DD') // 周一日期
      // let end = this.$moment(this.imDate).add(7 - weekOfday, 'days').format('YYYY-MM-DD') // 周日日期
      // dateInfo.startStr=start;
      // dateInfo.endStr=end;
    }
  },
};
</script>
<style lang="less">
.course-table-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .operation {
    margin-top: 16px;
  }
  .close {
    position: absolute;
    right: 32px;
    top: 32px;
    cursor: pointer;
  }
}

.calendarPopover {
  .ant-tooltip-inner {
    border-radius: 4px;
  }
}
p {
  margin-bottom: 1em;
}
.demo-app {
  display: flex;
  height: calc(100vh - 92px);
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  background-color: #fff;
  border-radius: 20px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}
.tooltips {
  max-width: 100%;
  .ant-tooltip-inner {
    background-color: #fff;
    color: #333;
    padding: 20px;
  }
  .ant-tooltip-arrow::before {
    background-color: #fff;
  }
}
.courseTime {
  .ant-popover-inner {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }
  .ant-popover-inner-content {
    background-color: rgba(255, 238, 238, 0.8);
    border-radius: 8px;
    color: #ff5353;
    padding: 20px;
  }
  .ant-popover-arrow {
    border-top-color: rgba(255, 238, 238, 0.5) !important;
    border-left-color: rgba(255, 238, 238, 0.5) !important;
  }
}
.allTime {
  font-size: 20px;
  color: #333;
  font-weight: 600;
}
.demo-app-main {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 10px;
  overflow-y: auto;
  .statistics-time {
    position: absolute;
    left: 0;
    top: 15px;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .schedule-color {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    i {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 4px;
      margin: 0 5px 0 20px;
    }
    span {
      background-color: transparent !important;
    }
    .schedule-blue {
      background-color: #009cff;
      color: #009cff;
    }
    .schedule-green {
      background-color: #00cf64;
      color: #00cf64;
    }
    .schedule-gray {
      background-color: #999;
      color: #333;
    }
  }
  .fc-timegrid-body {
    width: 100% !important;
  }
  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #43d186;
    border-color: #43d186;
  }
  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
  .fc .fc-button-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(67, 209, 134, 0.3);
  }
  .fc .fc-button-primary:focus {
    background-color: #43d186;
    border-color: #43d186;
    color: #fff;
  }
  .fc .fc-button-primary {
    color: #333;
    background-color: #f7f7f7;
    border-color: #f7f7f7;
  }
  .fc-timegrid-event-harness-inset .fc-timegrid-event {
    .fc-event-main {
      padding: 0;
    }
  }
  .fc .fc-toolbar {
    display: flex;
    justify-content: flex-end;
    .fc-toolbar-chunk {
      // float: left;
      // margin-right: 20px;
    }
    .fc-toolbar-chunk:nth-last-child(1) {
      // float: right;
    }
    .fc-toolbar-title {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .coursePopover .ant-popover-arrow {
    display: none;
  }
  .fc-scrollgrid-sync-inner a {
    color: #333;
  }
  .fc-day-today .fc-scrollgrid-sync-inner a {
    color: #009cff;
  }
  .fc-day-today .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
    color: #009cff;
    &::after {
      content: '(今天)';
      display: inline-block;
      color: #009cff;
    }
  }
}
// .calendarTools .fc-toolbar {
//   height: 40px;
// }
.fc {
  /* the calendar root */
  // max-width: 1100px;
  margin: 0 auto;
}
.closed {
  float: right;
  font-size: 16px;
  color: rgba(204, 204, 204, 1);
}
// .dotcls::before {
//   content: '';
//   display: inline-block;
//   position: absolute;
//   width: 4px;
//   height: 4px;
//   background-color: red;
//   top: 0;
//   right: 0;
// }
.fc-timegrid-event-harness-inset .fc-timegrid-event {
  box-shadow: none;
  border: 1px solid #fff;
}
.eventCls {
  height: 100%;
  border-radius: 4px;
  // border-color: #005184 !important;
  box-shadow: 0px 0px 0px 2px var(--fc-page-bg-color, #005184);
}
.calendarLoading {
  width: 100%;
  height: 100vh;
  text-align: center;
  line-height: 100vh;
  position: fixed;
  top: 0;
  z-index: 1040;
  background-color: rgba(255, 255, 255, 0.4);
}
.course-title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
  display: flex;
}
.course-status {
  display: inline-block;
  height: 18px;
  border-radius: 4px;
  border: 1px solid #ffbf00;
  color: #ffbf00;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  margin-left: 10px;
  padding: 0 3px;
}
.course-info {
  font-size: 14px;
  color: #333333;
  line-height: 16px;
  & > span {
    display: inline-block;
    width: 70px;
    color: #999;
    margin-right: 5px;
    text-align: right;
  }
}

.course-form {
  label {
    margin-right: 20px;
  }
  .tips {
    font-size: 12px;
    font-weight: 400;
    color: #ff5353;
  }
  .ant-btn {
    width: 160px;
    height: 40px;
    border-radius: 10px;
  }
  .ant-btn-primary {
    background: #009cff;
    margin-left: 20px;
  }
}
.coursePopover {
  .ant-popover-content {
    width: 440px;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    background-color: #fff;
    padding: 20px 16px;
    .ant-popover-inner {
      box-shadow: none;
      .ant-popover-inner-content {
        padding: 0;
      }
    }
  }
  img {
    float: right;
    margin-top: -50px;
  }
  .operation {
    display: block;
    width: 50px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    color: #fff;
    border-radius: 4px;
    background-color: #009cff;
    float: right;
  }
}
.statusFlag {
  margin-left: 2px;
}
.classToStart {
  color: rgb(253, 197, 10);
  background: rgb(253, 242, 204);
}
.completed {
  color: rgb(105, 255, 5);
  background-color: rgb(227, 253, 209);
}
.cancelled {
  color: rgb(83, 83, 83);
  background-color: rgb(237, 238, 236);
}
.absent {
  background-color: rgb(252, 231, 231);
  color: rgb(255, 110, 85);
}
.fc .fc-timegrid-slot {
  height: 2.6em !important;
}
.section-btn {
  padding: 2px 5px;
  margin-right: 10px;
  display: inline-block;
  border: 1px solid #c1c0c0;
  border-radius: 5px;
  cursor: pointer;
}
.schedulename-wrap {
  display: inline-block;
  max-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.schedulename-wrap:hover {
  overflow: visible;
  white-space: inherit;
}
.section-flag {
  display: inline-block;
  padding: 2px 4px;
}
.goClass {
  padding: 4px 12px;
  border: 1px solid #43d186;
  border-radius: 5px;
  color: #43d186;
  font-size: 14px;
  cursor: pointer;
}
.schedule-btnWrap {
  margin-right: 20px;
}
.classicon_enter {
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
}
.backMyStudent1 {
  position: absolute;
  left: 0;
  top: 15px;
  margin: 10px 0;
  font-size: 16px;
}
.backStudentName {
  color: #43d186;
  cursor: pointer;
}
.demo-app-main .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) {
  margin-right: 20px;
}
.demo-app-main .fc .fc-toolbar .fc-toolbar-chunk:nth-child(2) {
  margin-right: 10px;
}
.fc .fc-timegrid-col-events {
  z-index: inherit !important;
}
.look-btnwrap {
  display: flex;
  justify-content: space-around;
  padding-top: 8px;
}
.look-btn {
  width: 24px;
  height: 24px;
  display: inline-block;
}
.playbackclass {
  background: url('~@/assets/playbackclass.png') no-repeat center;
  background-size: 100%;
  &:hover {
    background: url('~@/assets/playbackclass_ed.png') no-repeat center;
    background-size: 100%;
  }
}
.teachfocus {
  background: url('~@/assets/teachfocus.png') no-repeat center;
  background-size: 100%;
  &:hover {
    background: url('~@/assets/teachfocus_ed.png') no-repeat center;
    background-size: 100%;
  }
}
.homework {
  background: url('~@/assets/homework.png') no-repeat center;
  background-size: 100%;
  &:hover {
    background: url('~@/assets/homework_ed.png') no-repeat center;
    background-size: 100%;
  }
}
.study {
  background: url('~@/assets/study.png') no-repeat center;
  background-size: 100%;
  &:hover {
    background: url('~@/assets/study_ed.png') no-repeat center;
    background-size: 100%;
  }
}
.math-check {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #009cff;
    color: #009cff;
    border-color: #009cff;
  }
}
.english-check {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f99f0f;
    color: #f99f0f;
    border-color: #f99f0f;
  }
}
</style>
