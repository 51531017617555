<template>
  <a-spin :spinning="loading">
    <div>
      <div class="header-top">
        <div class="left-wrap">
          <div class="header-title">当前学员</div>
          <div class="student-info">
            <div>
              <img :src="studentProfile.headImageUrl" class="student-avtor" />
            </div>
            <div>
              <div>
                <span style="margin-left: 10px">{{ studentProfile.fullName }}</span>
                <span style="color: rgb(102, 102, 102)">（{{ studentProfile.code }}）</span>
                <a-tag color="orange" v-show="isOffCamera">已关闭回放录制</a-tag>
              </div>
              <!-- <div class="bot-infowrap">
                <img src="@/assets/timeicon.png" style="width: 12px; height: 12px" />
                <span class="remaining-title">剩余课节:</span>
                <span class="remaining-text"
                  >中文
                  <span>2</span>
                </span>

                <span class="line"></span>
                <span class="remaining-text"
                  >数学
                  <span>1</span>
                </span>
              </div> -->
            </div>
          </div>
        </div>
        <div class="right-wrap">
          <span class="goClass" @click="goClass">去排课</span>
        </div>
      </div>
      <div class="poster-container">
        <a-tabs class="poster-content" @change="handleClick">
          <a-tab-pane key="list" tab="课节列表">
            <CalendarList v-if="studentProfile.uuid" :studentProfile="studentProfile || {}"></CalendarList>
          </a-tab-pane>
          <a-tab-pane key="calendar" tab="课节日历">
            <class-calendar></class-calendar>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </a-spin>
</template>

<script>
import storage from 'store';

import { getPosterList, queryExpandSubjectPoster } from '@/api/poster';
import { queryStudentDetailHead } from '@/api/headTeacher';
import ClassCalendar from './ClassCalendar.vue';
import CalendarList from './CalendarList.vue';

export default {
  components: {
    ClassCalendar,
    CalendarList,
  },
  data() {
    return {
      loading: false,
      posterList: [],
      activeName: 'share',
      subjectName: '',
      studentList: [],
      studentProfile: {},
      type: '',
      userID: storage.get('userInfo').userID,
    };
  },
  computed: {
    // 学生是否关闭了录制
    isOffCamera() {
      const isOnRecord = this.studentProfile?.data?.mp4Record;
      if (typeof isOnRecord === 'boolean') {
        return !isOnRecord;
      }
      // 默认打开，不展示标签
      return false;
    },
  },
  async created() {
    this.loading = true;

    // 多娃的海报
    this.type = this.$route.query.type;
    // 一家多娃
    if (this.type === 'TRACK_POSTER') {
      this.activeName = 'child';
    } else if (this.type === 'MINI_PROGRAM_POSTER') {
      // 学生分享
      this.activeName = 'share';
    }

    // 暂且修复一个bug
    try {
      const detail = await queryStudentDetailHead({ studentId: this.$route.query.uuid }).then((res) => {
        return res.data.content;
      });

      if (detail?.classAdmin?.CHINESE?.accountId === this.userID) {
        // 中文班主任
        detail.percentSubject = 'CHINESE';
      }

      if (detail?.classAdmin?.MATH?.accountId === this.userID) {
        detail.percentSubject = 'MATH';
      }

      detail.uuid = this.$route.query.uuid;
      this.studentProfile = detail;

      this.subjectName = this.studentProfile.percentSubject; // 是数学班主任还是中文班主任
      await this.getPosterListData('');
    } finally {
      this.loading = false;
    }
  },
  methods: {
    goClass() {
      // 去排课
      // window.open(process.env.VUE_APP_CLASS_URL);
      const routeUrl = this.$router.resolve({
        path: '/workoutSchedule/schedule',
      });
      window.open(routeUrl.href, '_blank');
    },
    // 拓科海报查询
    queryExpandSubjectPoster() {
      this.loading = true;

      const params = {
        subject: this.subjectName === 'CHINESE' ? 'Chinese' : 'Math',
        classAdmin: this.userID,
      };
      queryExpandSubjectPoster(params)
        .then((res) => {
          const { data } = res;

          this.posterList = data?.content || [];
        })
        .finally(() => (this.loading = false));
    },
    // // 搜索服务学生列表
    // getStudentInfo() {
    //   let params={

    //   }
    //   getStudentInfoPage().then((res) => {
    //     const data = res.data.content;
    //     const studentList = data.list;
    //     this.$store.commit('updatePosterStudentList', studentList);
    //   });
    // },
    async getPosterListData(param, type = '') {
      const params = {
        type: this.type,
      };
      await getPosterList(param, params).then((res) => {
        const { data } = res;
        if (type === 'math') {
          this.posterList = data?.content?.appletPosterMath || [];
          return;
        }
        this.posterList = data?.content || [];
      });
    },
    changeSubject(e) {
      const name = e.target.value;
      if (name === 'CHINESE' && (this.activeName === 'share' || this.activeName === 'child')) {
        this.getPosterListData('');
      } else if (name === 'MATH' && (this.activeName === 'share' || this.activeName === 'child')) {
        this.getPosterListData('math', 'math');
      } else if (this.activeName === 'expand') {
        this.queryExpandSubjectPoster();
      }
    },
    handleClick(tab) {
      const { name } = tab;
      this.subjectName = this.studentProfile.percentSubject;
      this.activeName = name;
      if (this.activeName === 'child') {
        this.type = 'TRACK_POSTER';
      } else if (this.activeName === 'share') {
        this.type = 'MINI_PROGRAM_POSTER';
      }
      if (this.subjectName === 'CHINESE' && (this.activeName === 'share' || this.activeName === 'child')) {
        this.getPosterListData('');
      } else if (this.subjectName === 'MATH' && (this.activeName === 'share' || this.activeName === 'child')) {
        this.getPosterListData('math', 'math');
      } else if (this.activeName === 'expand') {
        this.subjectName = this.studentProfile.percentSubject === 'CHINESE' ? 'MATH' : 'CHINESE';

        this.queryExpandSubjectPoster();
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.poster-container {
  padding: 20px;
  background: #ffffff;
}
.poster-content {
  min-height: calc(100vh - 130px);
}

.header-top {
  padding: 18px 20px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.header-title {
  font-size: 16px;
  font-weight: 600;
}
.student-avtor {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.student-info {
  font-size: 16px;
  color: #333;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.share-btnWrap {
  height: 36px;
  background: #f7f7f7;
  border-radius: 10px;
  line-height: 36px;
  padding: 0 10px;
  cursor: pointer;
  margin-left: 10px;
}

.icon_enter {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.goClass {
  padding: 4px 12px;
  border: 1px solid #43d186;
  border-radius: 5px;
  color: #43d186;
  font-size: 14px;
  cursor: pointer;
}
.bot-infowrap {
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 4px;
}
.remaining-text {
  color: rgba(0, 0, 0, 0.4);
  margin-right: 12px;
}
.line {
  width: 1px;
  height: 14px;
  margin: 0 12px 0 0;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 0px 2px 2px 0px;
}
</style>
