<template>
  <div style="display: contents">
    <a @click="showDrawer">查看反馈</a>
    <a-drawer
      title="查看反馈"
      placement="right"
      :visible="visible"
      @close="onClose"
      width="600"
      :drawerStyle="{ height: '100%', display: 'flex', 'flex-direction': 'column' }"
      :bodyStyle="{ flex: 1, overflow: 'auto' }"
    >
      <a-spin :spinning="loading">
        <div class="title-wrap">
          <span class="courseName">{{ listRecord.courseScheduleRespDTO.scheduleName }}</span>
          <a-tag color="orange">{{ ClassTypeEnumText[listRecord.courseScheduleRespDTO.classType] }}</a-tag>
        </div>
        <div class="date-wrap">
          <span>{{ listRecord.courseScheduleRespDTO.courseSectionRespDTO.structure }}</span>
          <span class="date-split-line">|</span>
          <span class="date">{{ courseDateTime }}</span>
        </div>
        <a-list class="list" item-layout="horizontal" :data-source="feedBackList">
          <a-list-item slot="renderItem" slot-scope="item">
            <a-descriptions :column="1">
              <a-descriptions-item>
                <div slot="label" class="desc-label">学习期望</div>
                {{ item.reason || '-' }}
              </a-descriptions-item>
              <a-descriptions-item>
                <div slot="label" class="desc-label">详细说明</div>
                {{ item.remark || '-' }}
              </a-descriptions-item>
              <a-descriptions-item>
                <div slot="label" class="desc-label">上传图片</div>
                <template v-if="item.picture && item.picture.length">
                  <el-image
                    :src="src"
                    v-for="src in item.picture"
                    :key="src"
                    fit="cover"
                    :preview-src-list="item.picture"
                  />
                </template>
                <span v-else>-</span>
              </a-descriptions-item>
              <a-descriptions-item>
                <div slot="label" class="desc-label">老师已读状态</div>
                {{ item.teacherRead ? '是' : '否' }}
              </a-descriptions-item>
              <a-descriptions-item>
                <div slot="label" class="desc-label">已读时间</div>
                {{ item.teacherReadTime ? $moment(item.teacherReadTime).format('YYYY-MM-DD HH:mm:ss') : '-' }}
              </a-descriptions-item>
            </a-descriptions>
          </a-list-item>
        </a-list>
      </a-spin>
    </a-drawer>
  </div>
</template>
<script>
import { Image } from 'element-ui';
import { getFeedbackListByCourseScheduleId } from '@/api/schedule';
import { ClassTypeEnumText, WeekEnumText } from '@/enum/index';

export default {
  props: {
    listRecord: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ElImage: Image,
  },
  data() {
    return {
      visible: false,
      feedBackList: [],
      ClassTypeEnumText,
      loading: false,
    };
  },
  computed: {
    courseDateTime() {
      const { startDateTime, endDateTime } = this.listRecord.courseScheduleRespDTO;
      if (!startDateTime) return '-';
      const startMoment = this.$moment(startDateTime);
      const endMoment = this.$moment(endDateTime);

      return `${startMoment.format('YYYY年MM月DD日')} ${WeekEnumText[startMoment.get('day')]} ${startMoment.format(
        'HH:mm',
      )}-${endMoment.format('HH:mm')}`;
    },
  },

  watch: {
    visible(v) {
      if (v) {
        this.handleGetList();
      }
    },
  },
  methods: {
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    async handleGetList() {
      this.loading = true;
      const { data = [] } = await getFeedbackListByCourseScheduleId(this.listRecord.courseScheduleId);

      this.feedBackList = data.filter(item=>item.type === 'BEFORE_SCHEDULE');
      this.loading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.title-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;

  .courseName {
    font-size: 20px;
    font-weight: 600;
  }
}

.date-wrap {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 8px;
  margin-bottom: 16px;
  .date-split-line {
    margin: 0 8px;
  }
  .date {
    opacity: 0.6;
  }
}
.desc-label {
  display: inline-block;
  width: 86px;
  text-align: right;
}
.el-image {
  width: 72px;
  height: 72px;
}
/deep/.ant-descriptions-item-content {
  display: inline-flex;
  gap: 8px;
}
</style>
