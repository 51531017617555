<template>
  <div class="course-table-box">
    <img class="bg" src="./assets/cell-bg.png" />
    <img class="header-bg" src="./assets/header.png" />
    <img class="footer-bg" src="./assets/footer.png" />
    <div class="content">
      <img class="title" src="./assets/title.png" />
      <div class="stu-info">
        <div class="info-item">
          <span class="label">Student:</span>
          <span class="value stu-name">{{ formatStuName }}</span>
        </div>
        <i class="split-line" />
        <div class="info-item">
          <span class="label">Valid:</span>
          <span class="value">{{ validDate }}</span>
        </div>
      </div>
      <div class="time-zone">
        <div class="info-item">
          <span class="label">Time Zone:</span>
          <span class="value">{{ timezoneText }}</span>
        </div>
      </div>
      <div class="table-wrap">
        <img class="table-content-bg" src="./assets/table-bg.png" />
        <div class="table-content">
          <i class="am-pm-split-line" :style="{ bottom: this.timelineHight / 2 + 'px' }" />
          <div class="am-pm-split-tag">
            <div class="title-blank"></div>
            <div class="am-pm" :style="{ height: timelineHight + 'px' }">
              <img class="am" src="./assets/am.png" />
              <img class="pm" src="./assets/pm.png" />
            </div>
          </div>
          <div class="date-row">
            <div class="date-col" v-for="item in calenderDataFormatList" :key="item.weekDay">
              <div
                class="date-title"
                :style="{
                  background: item.titleBg,
                  color: item.titleColor,
                }"
              >
                <div class="week">{{ item.weekDayText }}</div>
                <div class="date">
                  <span>{{ item.date }}</span>
                  <span>{{ item.monthText }}</span>
                </div>
              </div>
              <div
                class="time-line"
                :style="{
                  background: item.timeLineBg,
                  height: timelineHight + 'px',
                }"
              >
                <div class="course-hier">
                  <div class="course-item am" v-for="(course, courseIdx) in item.courseHierarchy.am" :key="courseIdx">
                    <img class="course-item-bg" :src="getCourseBg(course)" />
                    <div class="course-item-info">
                      <span class="date">{{ formatDate(course) }}</span>
                      <span class="subject">{{ toTitleCase(course.subject) }}</span>
                    </div>
                  </div>
                </div>
                <div class="course-hier">
                  <div class="course-item pm" v-for="(course, courseIdx) in item.courseHierarchy.pm" :key="courseIdx">
                    <img class="course-item-bg" :src="getCourseBg(course)" />
                    <div class="course-item-info">
                      <span class="date">{{ formatDate(course) }}</span>
                      <span class="subject">{{ toTitleCase(course.subject) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hint">
          <span
            >1. All course times are in the student's local time zone. Ensure it's accurate, especially during daylight
            saving time changes.</span
          >
          <span
            >2. This schedule covers {{ validDateAddYear }}. For courses on other dates, please check the app
            schedule.</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chineseCard from './assets/chinese-card.png';
import mathCard from './assets/math-card.png';
import englishCard from './assets/english-card.png';
import completeCard from './assets/complete-card.png';
import { SubjectEnum } from '@/enum';

export default {
  props: {
    currentDateRange: {
      type: Array,
      default: () => [],
    },
    calenderData: {
      type: Object,
      default: () => ({}),
    },
    studentProfile: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      timelineHight: 600,
      weekOptionMap: {
        0: {
          text: 'Sun',
          color: '#D15263',
          backgroundColor: 'rgba(255, 124, 142, 0.2)',
          timeLineBg: 'rgba(225, 228, 251, 0.2)',
        },
        1: {
          text: 'Mon',
          color: '#A8609D',
          backgroundColor: 'rgba(207, 143, 197, 0.2)',
          timeLineBg: 'rgba(255, 229, 232, 0.2)',
        },
        2: {
          text: 'Tus',
          color: '#589C5A',
          backgroundColor: 'rgba(141, 210, 143, 0.2)',
          timeLineBg: 'rgba(245, 233, 243, 0.2)',
        },
        3: {
          text: 'Wed',
          color: '#4F87D5',
          backgroundColor: 'rgba(137, 177, 233, 0.2)',
          timeLineBg: 'rgba(232, 246, 233, 0.2)',
        },
        4: {
          text: 'Thu',
          color: '#F06E65',
          backgroundColor: 'rgba(255, 141, 133, 0.2)',
          timeLineBg: 'rgba(231, 239, 251, 0.2)',
        },
        5: {
          text: 'Fri',
          color: '#43A1B5',
          backgroundColor: 'rgba(122, 192, 206, 0.2)',
          timeLineBg: 'rgba(255, 232, 231, 0.2)',
        },
        6: {
          text: 'Sat',
          color: '#545DB0',
          backgroundColor: 'rgba(107, 120, 237, 0.2)',
          timeLineBg: 'rgba(228, 242, 245, 0.2)',
        },
      },
      monthTextMap: {
        0: 'January',
        1: 'February',
        2: 'March',
        3: 'April',
        4: 'May',
        5: 'June',
        6: 'July',
        7: 'August',
        8: 'September',
        9: 'October',
        10: 'November',
        11: 'December',
      },
    };
  },
  watch: {
    currentDateRange(val) {
      console.log('currentDateRange', val);
    },
    calenderData(val) {
      console.log('calenderData', val);
    },
    studentProfile: {
      handler(val) {
        console.log('studentProfile', val);
      },
      immediate: true,
    },
  },

  computed: {
    formatStuName() {
      const name = this.studentProfile.englishName || this.studentProfile.fullName;

      if (name.length <= 8) return name;
      return name.substring(0, 8) + '...';
    },

    timezoneText() {
      if (!this.studentProfile?.timezone) return '-';
      const gmt = this.studentProfile.timezone.split(')')[0] + ')';

      return gmt + ' ' + this.studentProfile.timezoneStd;
    },
    validDate() {
      const startMoment = this.$moment(this.currentDateRange[0]);
      const endMoment = this.$moment(this.currentDateRange[1]).subtract(1, 'days');

      return (
        startMoment.get('date') +
        ' ' +
        this.monthTextMap[startMoment.get('month')].substring(0, 3) +
        ' - ' +
        endMoment.get('date') +
        ' ' +
        this.monthTextMap[endMoment.get('month')].substring(0, 3)
      );
    },
    validDateAddYear() {
      const startMoment = this.$moment(this.currentDateRange[0]);
      const endMoment = this.$moment(this.currentDateRange[1]);

      let startFormat = startMoment.get('date') + ' ' + this.monthTextMap[startMoment.get('month')].substring(0, 3);
      let endFormat =
        endMoment.subtract(1, 'days').get('date') + ' ' + this.monthTextMap[endMoment.get('month')].substring(0, 3);

      const startYear = startMoment.get('year');
      const endYear = endMoment.get('year');

      startFormat += ' ' + startYear;
      endFormat += ' ' + endYear;

      return startFormat + ' - ' + endFormat;
    },

    calenderDataFormatList() {
      if (!this.currentDateRange.length) return [];

      const start = this.$moment(this.currentDateRange[0]).startOf('day');
      const end = this.$moment(this.currentDateRange[1]).startOf('day');

      const dates = [start.clone()];

      // 7天的数据
      while (start.add(1, 'days').diff(end) < 0) {
        dates.push(start.clone());
      }

      const courseData = Object.values(this.calenderData)
        .flat()
        .filter((item) => ['NORMAL', 'COMPLETED'].includes(item.courseSchedule.scheduleStatus))
        .filter((item) => ['STANDARD', 'TRIAL'].includes(item.courseSchedule.data.courseType));

      // 算出这周内，一个半天最多有多少节课，动态划分区域
      const halfDayMap = courseData.reduce((p, c) => {
        const time = this.$moment(c.courseSchedule.startStudentDateTime);

        const date = time.format('YYYY-MM-DD');
        const half = time.get('hour') >= 12 ? 'pm' : 'am';
        const syb = date + ' ' + half;

        return { ...p, [syb]: (p[syb] || 0) + 1 };
      }, {});
      let halfDayMax = Math.max(...Object.values(halfDayMap));
      // 最少半天分为3层
      halfDayMax = halfDayMax > 3 ? halfDayMax : 3;

      this.timelineHight = halfDayMax * 2 * 100;

      // 整理json数据，包含样式等
      const formatDataList = dates.map((item) => {
        const weekDay = item.get('day');
        const month = item.get('month');
        const weekOption = this.weekOptionMap[weekDay];

        // 过滤出同一天的课
        const courseList = courseData.filter((sub) =>
          this.$moment(sub.courseSchedule.startStudentDateTime).isSame(item, 'day'),
        );

        // 动态划分
        const courseHierarchy = courseList.reduce(
          (p, c) => {
            const hour = this.$moment(c.courseSchedule.startStudentDateTime).get('hour');
            const hie = hour >= 12 ? 'pm' : 'am';

            const { subject } = c.courseSchedule.data;
            const { scheduleStatus } = c.courseSchedule;
            const { startStudentDateTime } = c.courseSchedule;
            const { endStudentDateTime } = c.courseSchedule;
            p[hie].push({
              subject,
              scheduleStatus,
              startStudentDateTime,
              endStudentDateTime,
            });

            p[hie].sort((a, b) => {
              return this.$moment(a.startStudentDateTime).valueOf() - this.$moment(b.startStudentDateTime).valueOf();
            });

            return p;
          },
          {
            am: [],
            pm: [],
          },
        );

        return {
          moment: item,
          weekDay,
          weekDayText: weekOption.text,
          titleBg: weekOption.backgroundColor,
          titleColor: weekOption.color,
          timeLineBg: weekOption.timeLineBg,
          date: item.get('date'),
          month,
          monthText: this.monthTextMap[month].substring(0, 3),
          courseHierarchy,
        };
      });

      return formatDataList;
    },
  },
  methods: {
    getCourseBg(courseItem) {
      if (courseItem.scheduleStatus === 'COMPLETED') return completeCard;
      const SubjectBgMap = {
        [SubjectEnum.CHINESE]: chineseCard,
        [SubjectEnum.MATH]: mathCard,
        [SubjectEnum.ENGLISH]: englishCard,
      };

      return SubjectBgMap[courseItem.subject];
    },
    toTitleCase(str) {
      return str.toLowerCase().replace(/(^|\s)\S/g, (t) => {
        return t.toUpperCase();
      });
    },
    formatDate(course) {
      const { startStudentDateTime, endStudentDateTime } = course;
      return (
        this.$moment(startStudentDateTime).format('HH:mm') + '-' + this.$moment(endStudentDateTime).format('HH:mm')
      );
    },
  },
};
</script>
<style lang="less">
.course-table-box {
  width: 1920px;
  // height: 1494px;
  position: relative;
  overflow: hidden;
  background: #dbf1fe;
  .bg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .header-bg {
    width: 100%;
    position: absolute;
    left: 0;
    top: -8px;
    z-index: 1;
  }
  .footer-bg {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

  .content {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 90px 50px 124px;
    line-height: normal;
    .title {
      width: 767px;
    }
    .stu-info {
      display: flex;
      margin-top: 16px;
    }

    .split-line {
      &::before {
        content: '|';
        display: flex;
        align-items: center;
        color: #005289;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        margin: 0 16px;
      }
    }
    .info-item {
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 16px;
      .label {
        color: rgba(0, 82, 137, 0.6);
      }
      .value {
        color: #005289;
      }
    }
    .time-zone {
      margin-top: 24px;
    }
    .table-wrap {
      width: 100%;
      // height: 1018px;
      margin-top: 64px;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 56px 40px 90px 60px;
      .table-content-bg {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 1;
      }
      .table-content {
        width: 100%;
        position: relative;
        z-index: 2;
        display: flex;

        .am-pm-split-line {
          width: 100%;
          height: 1px;
          background: #96c8e9;
          position: absolute;
          // bottom: 300px;
          z-index: -1;
        }

        .am-pm-split-tag {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          margin-right: 36.5px;
          .title-blank {
            height: 90px;
            width: 100%;
          }
          .am-pm {
            display: flex;
            flex-direction: column;
            margin-top: 58px;
            justify-content: space-around;
            .am,
            .pm {
              width: 64.5px;
              height: 127px;
            }
          }
        }
        .date-row {
          display: flex;
          flex: 1;
          height: 100%;
          justify-content: space-between;
          .date-col {
            display: flex;
            flex-direction: column;

            .date-title {
              width: 208px;
              height: 90px;
              border-radius: 45px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .week {
                font-size: 30px;
                font-weight: 600;
              }
              .date {
                margin-top: 8px;
                font-size: 20px;
                display: flex;
                gap: 8px;
              }
            }
            .time-line {
              margin-top: 58px;
              display: flex;
              flex-direction: column;
              width: 100%;
              .course-hier {
                height: 100%;

                .course-item {
                  height: 100px;
                  position: relative;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  .course-item-bg {
                    width: 100%;
                    height: 105.5px;
                    position: absolute;
                    left: 0;
                    top: -5.5px;
                    z-index: 0;
                  }
                  .course-item-info {
                    height: 100%;
                    color: #fff;
                    display: inline-flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 3px;
                    position: relative;
                    z-index: 1;
                    // padding-left: 28px;
                    .date {
                      font-size: 28px;
                      font-weight: 600;
                    }
                    .subject {
                      font-size: 24px;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .hint {
        color: #666;
        font-size: 24px;
        line-height: 42px;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        margin: 40.5px 0 0 100px;
      }
    }
  }
}
</style>
