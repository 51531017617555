<template>
  <ModalForm
    title="学员课节反馈"
    formRef="feedbackFormRef"
    :visible="visible"
    @visibleChange="onVisibleChange"
    :onFinish="handleSubmit"
  >
    <a slot="trigger">反馈</a>

    <template slot="form">
      <a-form-item label="当前学员"> {{ studentProfile.fullName }}({{ studentProfile.code }}) </a-form-item>
      <a-form-item label="当前课节">
        <div class="hide-single-line">
          <a-tooltip>
            <template slot="title"> {{ currentCourse }} </template>
            {{ currentCourse }}
          </a-tooltip>
        </div>
      </a-form-item>
      <a-form-item label="当前课节状态">
        <a-input :value="ScheduleStatusEnumText[scheduleStatus]" disabled />
      </a-form-item>
      <a-form-item label="反馈分类">
        <a-select
          placeholder="请选择"
          :options="Object.entries(feedbackTypeEnumText).map((item) => ({ label: item[1], value: item[0] }))"
          v-decorator="[
            'category',
            {
              rules: [{ required: true, message: '请选择反馈分类' }],
            },
          ]"
          @change="feedbackTypeChange"
        />
      </a-form-item>
      <a-form-item label="反馈原因" v-if="!!feedbackReasonEnum">
        <a-select
          placeholder="请选择"
          :options="Object.entries(feedbackReasonEnum).map((item) => ({ label: item[1], value: item[0] }))"
          v-decorator="[
            'reason',
            {
              rules: [{ required: true, message: '请选择反馈原因' }],
            },
          ]"
          mode="multiple"
        />
      </a-form-item>
      <a-form-item label="申请课时返还" v-if="scheduleStatus === ScheduleStatusEnum.COMPLETED">
        <a-select
          placeholder="请选择"
          :options="[{ label: '是', value: 1 }, ...(canSelectNo ? [{ label: '否', value: 0 }] : [])]"
          v-decorator="[
            'cuReturn',
            {
              rules: [{ required: true, message: '请选择申请课时返还' }],
            },
          ]"
          @change="refundChange"
        />
      </a-form-item>
      <a-form-item label="课时数量" v-if="showUnitCount">
        <a-spin :spinning="unitCountLoading">
          <a-input v-decorator="['cuReturnNum', {}]" disabled />
        </a-spin>
      </a-form-item>
      <a-form-item label="飞书审批编号" v-if="showUnitCount">
        <a-input v-decorator="['approveNumber', {}]" :maxLength="32" />
      </a-form-item>
      <a-form-item label="详细说明">
        <a-textarea
          placeholder="请输入（500字以内）"
          :maxLength="500"
          v-decorator="[
            'remark',
            {
              rules: [{ required: true, message: '请输入反馈内容' }],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="上传图片">
        <CVImageArea
          ref="cvImageArea"
          :maxCount="9"
          v-decorator="[
            'picture',
            {
              rules: [{ required: true, message: '请上传图片' }],
            },
          ]"
        />
      </a-form-item>
    </template>
  </ModalForm>
</template>
<script>
import ModalForm from '@/components/ModalForm/index.vue';
import CVImageArea from '@/components/CVImageArea/index.vue';
import {
  ScheduleStatusEnumText,
  ScheduleStatusEnum,
  SubjectEnum,
  ClassTypeEnumText,
  CourseTypeEnumText,
} from '@/enum/index';
import { getCourseUnitTransaction, createCourseFeedback } from '@/api/schedule';

const feedbackTypeEnums = {
  EXPECT: 'EXPECT',
  FEED_BACK: 'FEED_BACK',
  OPERATION: 'OPERATION',
  FAULT: 'FAULT',
};

export default {
  components: { ModalForm, CVImageArea },
  watch: {
    studentProfile(e) {
      console.log(e);
    },
  },
  props: {
    studentProfile: {
      type: Object,
      default: () => ({}),
    },
    listRecord: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    currentCourse() {
      return `${this.listRecord.courseScheduleRespDTO?.scheduleName}（班级时区：${this.listRecord.courseScheduleRespDTO?.startDateTime}）`;
    },
    feedbackTypeEnumText() {
      if (this.listRecord.scheduleStatus === ScheduleStatusEnum.NORMAL) {
        return {
          [feedbackTypeEnums.EXPECT]: '学习期望',
        };
      }

      return {
        [feedbackTypeEnums.FEED_BACK]: '教学反馈',
        [feedbackTypeEnums.OPERATION]: '排课操作',
        [feedbackTypeEnums.FAULT]: '系统故障',
      };
    },

    scheduleStatus() {
      return this.listRecord.scheduleStatus;
    },
    subject() {
      return this.listRecord.courseScheduleRespDTO?.subject;
    },
    cuReturnNum() {
      const valueMap = {
        [SubjectEnum.CHINESE]: '',
        [SubjectEnum.MATH]: '',
        [SubjectEnum.ENGLISH]: '',
      };

      return valueMap[this.subject];
    },

    feedbackReasonEnumTextMap() {
      const EXPECT = {
        本节课注意事项: '本节课注意事项',
        未试听首课定级: '未试听首课定级',
      };

      const FEED_BACK = {
        老师未与家长沟通学情: '老师未与家长沟通学情',
        对学情沟通有异议: '对学情沟通有异议',
        对课节进度有异议: '对课节进度有异议',
        授课内容讲解有误: '授课内容讲解有误',
        老师教学态度不佳: '老师教学态度不佳',
        老师上课状态不佳: '老师上课状态不佳',
        老师教学方法不当: '老师教学方法不当',
        老师授课环境不佳: '老师授课环境不佳',
        课后或阶段点评不合规: '课后或阶段点评不合规',
        老师网络差: '老师网络差',
        老师未按时上课: '老师未按时上课',
      };

      const OPERATION = {
        课节未取消: '课节未取消',
        排课错误: '排课错误',
        沟通失误: '沟通失误',
      };

      const FAULT = {
        升级维护: '升级维护',
        登陆失败: '登陆失败',
        上课平台: '上课平台',
        请假失败: '请假失败',
      };

      if (this.subject !== SubjectEnum.CHINESE) {
        delete FEED_BACK['老师未与家长沟通学情'];
        delete FEED_BACK['对学情沟通有异议'];
        delete FEED_BACK['对课节进度有异议'];
        delete FEED_BACK['课后或阶段点评不合规'];
      }

      return {
        [feedbackTypeEnums.EXPECT]: EXPECT,
        [feedbackTypeEnums.FEED_BACK]: FEED_BACK,
        [feedbackTypeEnums.OPERATION]: OPERATION,
        [feedbackTypeEnums.FAULT]: FAULT,
      };
    },
  },
  data() {
    return {
      visible: false,
      ScheduleStatusEnumText,
      SubjectEnum,
      feedbackReasonEnum: undefined,
      showUnitCount: false,
      ScheduleStatusEnum,
      unitCountLoading: false,
      canSelectNo: this.listRecord?.courseScheduleRespDTO?.subject === SubjectEnum.CHINESE,
    };
  },
  methods: {
    onVisibleChange(e) {
      // 待开课课节只支持中文
      if (this.subject !== this.SubjectEnum.CHINESE && this.scheduleStatus === ScheduleStatusEnum.NORMAL) {
        return this.$message.error('当前不支持数学英文待开课课节反馈！');
      }
      this.visible = e;
    },
    feedbackTypeChange(v) {
      if (!v) {
        this.feedbackReasonEnum = undefined;
      }
      this.feedbackReasonEnum = this.feedbackReasonEnumTextMap[v];

      if ([feedbackTypeEnums.OPERATION, feedbackTypeEnums.FAULT].includes(v)) {
        this.canSelectNo = false;
      } else {
        this.canSelectNo = this.listRecord?.courseScheduleRespDTO?.subject === SubjectEnum.CHINESE;
      }

      this.$refs.feedbackFormRef.setFieldsValue({ reason: undefined, cuReturn: undefined });
    },
    async refundChange(v) {
      this.showUnitCount = !!v;
      if (!v) {
        this.$refs.feedbackFormRef.setFieldsValue({ cuReturnNum: undefined });
        return;
      }

      const cuReturnNum = this.$refs.feedbackFormRef.getFieldValue('cuReturnNum');
      if (cuReturnNum === undefined) {
        this.unitCountLoading = true;
        try {
          await this.handleGetCourseUnitTransaction();
        } catch {
          this.visible = false;
        } finally {
          this.unitCountLoading = false;
        }
      }
    },
    async handleGetCourseUnitTransaction() {
      const { data } = await getCourseUnitTransaction(this.listRecord.uuid);

      const cuReturnNum = data.content || 0;

      this.$refs.feedbackFormRef.setFieldsValue({ cuReturnNum });
    },
    async handleSubmit(values) {
      if (this.unitCountLoading) {
        this.$message.warning('课时数量正在请求中，请等待完成后提交');
        return false;
      }
      // 贴图上传组件需要在最后把base64给上传了
      const picture = await this.$refs.cvImageArea.uploadImages();
      const {
        courseScheduleRespDTO: {
          formClassId,
          formClassName,
          classType,
          data: { courseType },
        },
        studentRespDTO: { code, fullName, uuid: studentId },
        courseScheduleId,
        uuid: stuScheduleId,
      } = this.listRecord;

      const relatedClass = [
        {
          uuid: formClassId,
          className: formClassName,
          classType: ClassTypeEnumText[classType],
          courseType: CourseTypeEnumText[courseType],
          studentsArray: [{ name: fullName, code, id: studentId }],
        },
      ];

      try {
        await createCourseFeedback({
          ...values,
          courseScheduleId,
          cuReturn: !!values.cuReturn,
          picture,
          studentId,
          stuScheduleId,
          type: this.scheduleStatus === ScheduleStatusEnum.NORMAL ? 'BEFORE_SCHEDULE' : 'AFTER_SCHEDULE',
          variables: { relatedClass },
        });
        this.$message.success('创建成功');
        return true;
      } catch {
        return false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
// /deep/.ant-spin-container,
// .ant-spin-nested-loading {
//   height: 100%;
// }
</style>
