<template>
  <div ref="editorWrapperRef">
    <quill-editor
      ref="quillEditor"
      :options="editorOption"
      @change="onEditorChange($event)"
      :value="content"
      :style="editorStyle"
    >
    </quill-editor>
  </div>
</template>
<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';
import { getBase64Size, base64ToFile } from '@/utils/util';
import { uploadFile } from '@/api/common';

// 提交前记得调用这个组件的上传以替换base64

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      content: '',
      editorOption: {
        placeholder: 'Ctrl(Cmd)+V粘贴图片或点击左上角图标本地上传（最多支持9张）',
        theme: 'snow',
        modules: {
          toolbar: ['image'],
        },
      },
    };
  },
  props: {
    maxSize: {
      type: Number,
      default: 2, // 单位为M
    },
    maxCount: {
      type: Number,
      default: 9,
    },
    editorStyle: {
      type: Object,
      default: () => ({
        height: 'auto',
      }),
    },
    value: { type: Array },
    autoHeight: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 120,
    },
  },
  watch: {
    async value(val) {
      const div = document.createElement('div');
      div.id = 't' + Date.now() + Math.random();
      const imgDomStr = val ? val.map((item) => `<img src="${item}" />`).join('') : '';
      div.innerHTML = imgDomStr;
      this.content = div.outerHTML;
      await new Promise((resolve) => this.$nextTick(() => resolve()));
      this.editor.setSelection(this.editor.getLength(), this.editor.getLength());
    },
    autoHeight: {
      handler(val) {
        if (!this.$refs.editorWrapperRef) return;
        this.handleAutoHeight(val);
      },
    },
  },
  components: {
    quillEditor,
  },
  computed: {
    editor() {
      return this.$refs.quillEditor.quill;
    },
  },
  mounted() {
    this.handleAutoHeight(this.autoHeight);
  },
  methods: {
    handleAutoHeight(val) {
      const editorContent = this.$refs.editorWrapperRef?.getElementsByClassName('ql-editor')?.[0];
      if (!editorContent) return;
      if (!val) {
        editorContent.style.height = 'auto';
        editorContent.style.minHeight = this.height + 'px';
      } else {
        editorContent.style.height = this.height + 'px';
        editorContent.style.minHeight = 'unset';
      }
    },

    validate(src, index) {
      // 最大张数限制
      if (index + 1 > this.maxCount) {
        this.$message.error(`图片数量已超出限制${this.maxCount}张`);
        return false;
      }

      // 如果是http则证明是上传成功的，不用管
      if (src.includes('http')) return true;

      if (src.includes('//:0')) {
        this.$message.error(
          '浏览器应用无权限访问本地路径，如果你是用的企业微信复制图片，请点开大图复制，或下载到本地后进行上传',
        );
        return false;
      }

      const size = getBase64Size(src);
      const m = size / 1024 / 1024;

      if (m > this.maxSize) {
        this.$message.error(`图片大小已超出限制${this.maxSize}MB`);
        return false;
      }

      return true;
    },

    async onEditorChange({ html, text }) {
      const div = document.createElement('div');
      div.innerHTML = html;

      let srcList = [...div.getElementsByTagName('img')].map((item) => item.src);

      // 此刻证明不是上传的图片，在输入问题，需要把content复原
      if (text.trim()) {
        const d = document.createElement('div');
        d.id = 't' + Date.now() + Math.random();
        d.innerHTML = srcList.map((item) => `<img src="${item}"/>`).join('');
        this.content = d.outerHTML;
        await new Promise((resolve) => this.$nextTick(() => resolve()));
        this.editor.setSelection(this.editor.getLength(), this.editor.getLength());
        return;
      }

      // 这里return，避免很多次的重复change
      if (srcList.length === (this.value?.length ?? 0)) {
        return;
      }
      // 校验图片
      srcList = srcList.filter(this.validate);
      this.$emit('change', srcList);
    },
    // 上传富文本中的所有图片
    async uploadImages() {
      const div = document.createElement('div');
      div.innerHTML = this.content;
      const imgDomList = div.getElementsByTagName('img');

      const requestList = [...imgDomList].map(async (item) => {
        const { src } = item;
        // 如果不是base64就不用上传

        if (!src.includes('base64')) return src;

        const file = await base64ToFile(src);

        const url = await uploadFile(file);
        return url;
      });
      const urlList = await Promise.all(requestList);
      return urlList;
    },
  },
};
</script>
<style lang="less" scoped>
// /deep/.ql-editor {
//   height: auto;
//   min-height: 150px;
// }
</style>
